export const LINKOUT_ADVERTISEMENT_IDS = [
  // FC affilates
  "BvO5QhlavK8O", // affiliateId: 2175 [kredit-vergleichen.cc]
  "dXl4LtpmkD82",
  "W2XQ7I3ZeNvV",
  "dXl4LtpmEx7g",
  "dgv6PCakvgyG", // affiliateId: 2314 [krediteguide.de]
  "dGe4NczQo3bK",
  "dVAXwTM6bnNa",
  "d53ZyF7N3ZqK",
  "Bpk1wT578wxy",
  "Ww5M1IbYvYMg",
  "BzgG1sQeZeLX",
  "W2Xnyf5yy00P",
  "dXlPoSV1YVlM",
  "rkQYvS7NXPQ9",
  "BvO61UlKeYk4",
  "rENgxiZQ4Xqe",
  "BzgG1sQ4O8A9",
  "dLPveS3NgpXa",
  "Ww5M1I4Z3x7v",
  "dOG9jflZmzEg", // affiliateId: 2837 [netkredit24.de]
  "BvO0GClX6YM6",
  "WNO0nC36zjx8",
  "Wqkxbuw6omR4",
  "BvO0GClX6YAD",
  "db1LyCZNnkLO",
  "rkQLbC7QEb6w",
  "r1Zz6FXxQlDN",
  "WQNPntv8oAwe",
  "dyvPEFE4Y0MM",
  "W2XmoI3LlvJy",
  "W2XmoI39eAGg",
  "r8nVMHN8Jk89",
  "dj6L1tVZNDwl",
  "Wxwe6Cl6QDlk",
  "dOG9jflX7exo",
  "dyvPEFEeoGD8",
  "rENzMt6xvA11",
  "db1LyCZNnkzk",
  "dXlZ6tVDgLQE",
  "WqkxbupJ42g5",
  "W2XmoI5a8zg7",
  "d53xMSgz7egw",
  "dyvPEFE4Y0vy",
  "r1Zz6FXxQlmO",
  "W33ApSRqKv21",
  "BRyXMf6gR1R6",
  "rDxZMIeqN1N0",
  "dj6L1tVM3gzz",
  "dXlZ6tpP70vP",
  "Bm3zbSGQ8p3x",
  "rDxROHxjnL20", // affiliateId: 3419 [bonify.de (Affiliate)]
  "dj6OwHwamZjO",
  "d7pnmHY6oYne",
  "db1NXiKLqLm5",
  "db1NXiKL85nN",
  "dXlRQtVgw42A",
  "r6n5zU3EpGan",
  "rkQDEH1nlKon",
  "rDxROHxwoxMy",
  "We40vTZmEEL6",
  "BoNZ7U1wPVRl",
  "Bm3JlC4yvzlz",
  "WQNRbUqQRm02",
  "dXlRQtV8g8nL",
  "d407RTRVEX7D",
  "rJOxPsm3zR68", // affiliateId: 16754 [financescout24.de (new)]
  "Bo8XDiE4DKoj",
  "d48Yli6AEpjE",
  "rkY0ec7QZeGv",
  "d48Yli6AE7yV",
  "WQOeZsv87xYP",
  "WwMwjH4jDn14",
  "WKOKXsgORzEX",
  "Bv6aEhlXgMJb",
  "Wx7Elsly75eN",
  "Bv6aEhlXgE9x",
  "dZMa5HO9Qvna",
  "dbMzAHZNybzk",
  "Wx7Elsly75Om",
  "WqMDJHw6kRQA",
  "daMbmHKDLb73",
  "rAXYQs1x92j7",
  "rD3YNfeqQ2xz",
  "WYvZbSOMaDOj",
  "dXPg7spP1RpO",
  "dXPg7spP1Rlb",
  "WqMDJHw6kRqD",
  "WNPkvs3Xx4qM",
  "dXPg7sVARDNV",

  // SMAVA affiliates
  "BpVDGI5Gw2RM", // affiliateId:	6399 [sofortkredite-24.com]
  "affx1ae69305",
  "BoK6Yi1mA4Ev",
  "dVekKs36KEgO", // affiliateId:	6857 [kredite-ohne-schufa.info]
  "affx334c71ec",
  "db7aJUK7ExP1",
  "BzoNyIQyO5AM", // affiliateId: 9137 [testsieger-berichte.de]
  "rkmEys1PAVOv",
  "dZ9KvHq3eLRR",
  "affxb2260fef",
  "W3x8nSz9D044", // affiliateId:	9688 [kredit-zeit.de]
  "affxce73e1d3",
  "dMOy7cX5pNpK",
  "WK40EheE64Kq", // affiliateId:	9766 [blitz-kredite.com]
  "affxd2e7627a",
  "dMO9lIX5pNKA",
  "r9JVAtJxM400", // affiliateId:	10671 [kreditvergleich.com]
  "affx012028a8",
  "WQk0Qcqep8Qy",
  "rD6QzUxyjGaG", // affiliateId: 10851 [bonify.de]
  "d43y1T6MwM0D",
  "dZN4EUOOmlN9",
  "dX4oaIVYag7b",
  "affx9a949bb8",
  "affx9a949bb8",
  "d55YjHgxZ0n2", // affiliateId: 10921 [oeffentlicher-dienst-news.de]
  "d55YjHgz8mDa",
  "affxcf3ecd59",
  "d43YwIRvzqKn",
  "r6Jx7i3xa7Xn", // affiliateId: 12618 [netkredit24.de]
  "affx44070271",
  "W2VzEs58QEkG",
  
  // SHARED test affiliate
  "prkGzUkMyKlK",
]


